import React, { useEffect, useRef, useState, Suspense } from "react";
import { Watch } from 'react-loader-spinner'
import Spline from "@splinetool/react-spline";

import song from './assets/song.mp3';
import play from './assets/play-icon.png';

import './App.css';

const BouncingDots = () => {
  const [dots, setDots] = useState([]);

  const numberOfDots = 200;

  useEffect(() => {
    const newDots = [];
    for (let i = 0; i < numberOfDots; i++) {
      newDots.push({
        id: i,
        left: Math.random() * window.innerWidth, // Random start position (X)
        top: Math.random() * window.innerHeight, // Random start position (Y)
        dx: (Math.random() - 0.5) * 2, // Random speed (X)
        dy: (Math.random() - 0.5) * 2, // Random speed (Y)
        size: Math.random() * 10 + 5, // Random size between 5 and 15px
        color: `white`,
        scale: 1,
      });
    }
    setDots(newDots);

    const interval = setInterval(() => {
      setDots((prevDots) => {
        return prevDots.map((dot) => {
          let newLeft = dot.left + dot.dx;
          let newTop = dot.top + dot.dy;

          // Bounce off the edges
          if (newLeft < 0 || newLeft + dot.size > window.innerWidth) {
            dot.dx = -dot.dx; // Reverse X direction
          }
          if (newTop < 0 || newTop + dot.size > window.innerHeight) {
            dot.dy = -dot.dy; // Reverse Y direction
          }

          const newScale = Math.random() * 0.5 + 0.5;

          // Update dot position
          return {
            ...dot,
            left: newLeft,
            top: newTop,
            scale: newScale,
          };
        });
      });
    }, 16); // ~60 frames per second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className="dots-container">
      {dots.map((dot) => (
        <div
          key={dot.id}
          className="dot"
          style={{
            left: `${dot.left}px`,
            top: `${dot.top}px`,
            width: `${dot.size * dot.scale}px`, // Apply scale to width
            height: `${dot.size * dot.scale}px`, // Apply scale to height
            backgroundColor: dot.color,
          }}
        ></div>
      ))}
    </div>
  );
};

function App() {
  const audioRef = useRef();
  const [isHiddenCurtains, setIsHiddenCurtains] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const spline = useRef();

  function onLoad(splineApp) {
    // if (spline.current === undefined) {
    //   setIsLoading(true);
    // } else {
    //   setTimeout(() => setIsLoading(false), 2000)
    // };
    setIsLoading(false);
    spline.current = splineApp;
  }

  const hideCurtains = (e) => {
    if (isHiddenCurtains) return;
    spline.current.emitEvent('mouseDown', 'Front curtain')
    setTimeout(() => {
      setIsHiddenCurtains(true);
      audioRef.current.play();
      setIsPlayed(true);
    }, 1000);
  }

  const togglePlay = () => {
    setIsPlayed(!isPlayed);
    if (isPlayed) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  }

  return (
    <div className="App">
      {isLoading && (
        <div className="loader">
          <Watch
            visible={true}
            height="120"
            width="120"
            radius="48"
            color="#00E3A6"
            ariaLabel="watch-loading"
          />
          <span>Please wait...</span>
        </div>
      )}
      <Spline scene="https://prod.spline.design/8dOeOEBRCX4qaXJM/scene.splinecode" onLoad={onLoad} />
      {isPlayed && <BouncingDots />}
      <audio ref={audioRef} src={song} />
      <button onClick={hideCurtains} className="start_button" />
      {isHiddenCurtains &&
        <button className="play_button" onClick={togglePlay}>
          <img src={play} alt="play" />
        </button>
      }
    </div>
  );
}

export default App;